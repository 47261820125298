import { Data } from "./Data";

declare global {
    interface Window {
        trackEvent: any;
    }
}

export class Amplitud {
    public static addAmplitudeScripts = (): void => {
        // Crear el primer script elemento
        const amplitudeId = Amplitud.getAmplitudeId();
        if (!amplitudeId) return;
        var script1 = document.createElement('script');
        script1.src = `https://cdn.amplitude.com/script/${amplitudeId}.js`;

        // Crear el segundo script elemento
        var script2 = document.createElement('script');
        script2.textContent = `
            window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
            window.amplitude.init('${amplitudeId}', {
            "fetchRemoteConfig": true,
            "autocapture": true
            });
            window.trackEvent = function(eventName, eventProperties) { window.amplitude.logEvent(eventName, eventProperties); };
        `;

        // Agregar los scripts al head
        document.head.appendChild(script1);

        // Asegurarse de que el primer script cargue antes de ejecutar el segundo script
        script1.onload = function () {
            document.head.appendChild(script2);
        };
    }

    // Definir una función para enviar eventos 
    public static trackEvent(eventName: any, eventProperties: any) {
        const amplitudeId = Amplitud.getAmplitudeId();
        if (!amplitudeId) return;
        window.trackEvent(eventName, eventProperties);
    }

    public static activate = () => {
        if (typeof window !== 'undefined') {
            Amplitud.trackEvent("switch_activate", {});
        }
    };

    public static deactivate = () => {
       Amplitud.trackEvent("switch_deactivate", {});
    };

    public static funnelModalTimer = () => {
       Amplitud.trackEvent("Button_modal_timer", {});
    }

    static funnelModalAdress = () => {
       Amplitud.trackEvent("Button_modal_adress", {});
    }
    public static funnelAnonimo() {
       Amplitud.trackEvent('anonymous_product_view', {});
    }

    public static funnelSizeNotAvailable() {
       Amplitud.trackEvent('size_not_available', {});
    }

    public static funnelAddToCart() {
       Amplitud.trackEvent('add_to_cart', {});
    }

    public static funnelOTP() {
       Amplitud.trackEvent('otp', {});
    }

    public static funnelCheckout() {
       Amplitud.trackEvent('checkout', {});
    }

    public static funnelConfirmation() {
       Amplitud.trackEvent('confirmation', {});
    }

    public static funnelSuccess() {
       Amplitud.trackEvent('success', {});
    }

    public static getAmplitudeId() {
        const hostId = Data.getHostId();
        switch (hostId) {
             case "279038":  // "NewBalanceCO" 
                return 'aa44946535f4ab61573e30547e9134e2';
            case "589462":  // "Mali" 
                return '1e772f315d2a30c7c3a8bb7608aee2cf';
            /*case "785421":  // "Bamers" 
                return 'p0q9bzsorb';
            case "874530":  // "Trycloud" 
                return '00000';*/
            case "781264":  // "TheNorthFaceCo"
                return '65f3e8b9b2395897f4948c0d946d1048';
            /*case "554044":  // "BosiCO" 
                return 'p0q9vqtyrg';*/
            case "876870":  // "PilatosCO" 
                return '21140c1e5b0e1f81e8796d1b5f219aec'; 
            case "393901":  // "SuperDryCO" 
                return 'd28cf1e309bb8642f320c712afd8637f';
            /* case "693510":  // "DieselCO" 
                return 'p0qc460szb';*/
            case "333331":  // "Prochampions" 
                return '1f14721435e8716dec86287ed38a9398';
            case "956487":  // "SteveMaddenCO" 
                return 'dd0ed40c66591cd13096a584014aa7bf';
            /*case "4555531":  // "RunningBalboa" 
                return 'p0qe5jiz8u';
            case "314159":  // "ParisCL" 
                return '00000';*/
            case "218350":  // "VansCO" 
                return '205bb6ea0641a1a37cb8190a08cc0058';
            case "989764":  // "MFGirbaudCO" 
                return '1c6947d7a8c3850478bfc1f5dd8ebd35';
            /*case "635987":  // "NBxPilatos" 
                return '00000'; */
            default:
                return null;
        }
    }
}