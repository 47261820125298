import { Flex, Box, Link, Image, Button, useToast, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useWidget } from "../../providers/WidgetProvider";
import { useCityAddress } from "../../hooks/useCityAddress";
import { ProductService } from "../../services/ProductService";
import { ProductMinified } from "../../models/ProductModel";
import { newSvg } from "../../helper/Svg";
import { prepareModal } from "../../helper/Render";

export const AskForAddress = () => {
  const [image, setImage] = useState<string | null>(null)
  const widget = useWidget();
  const cityAddress = useCityAddress();
  const toast = useToast();

  if (widget == null) return <></>;
  prepareModal(widget.isOpenModalAskForAddress);

  const selectProduct = async() => {
    const response: ProductMinified | null = await ProductService.getProductImage(); 
    if (response == null) return;
    setImage(response.image);
  }
  
  useEffect(() => {
    selectProduct();
  }, []);

  const addCityAddress = async(): Promise<boolean> => {
    const message = await cityAddress.addCityAddress();
    if (message.code === 400) {
      toast({
        title: message.title,
        description: message.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  const handleAddAddress = async() => {
    widget.openCloseModalAskForAddress(false);
    const response = await addCityAddress();
    if (!response) return;
    widget.handlerSteps("product");
    widget.openWidget();
    widget.openCloseModalAddress(true);
    toast({
      title: "Bienvenido!",
      description: "Ahora puedes seleccionar hasta 3 productos para tu probador.",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
  };

  const closeModal = (event: any) => {
    if (event.target.id === "modal-ask-for-address") {
      widget.openCloseModalAskForAddress(false);
    }
  }

  return (
    <Box
      id="modal-ask-for-address"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      position={"fixed" }
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg={"rgba(0, 0, 0, 0.5)"}
      zIndex="9000"
      display={!widget.isOpenModalAskForAddress ? "none" : "flex"}
      onClick={closeModal}
    >
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border={"1px solid #ddd"}
        borderRadius={"15px"}
        w={"90%"}
        maxW="300px"
        display={!widget.isOpenModalAskForAddress ? "none" : "flex"}
        overflowY="auto"
      >
        <Flex 
          direction="column" 
          w={"100%"} 
          position={"relative"}
          >
            <Box position={"absolute"} >{newSvg}</Box>
            <Image id="image" src={ image ? image :  ""} alt="" mx="auto" my={"15px"} boxSize="80px" rounded="50px" shadow="lg" border="solid 1px white" opacity={1} fallbackSrc="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/cloud-loading.gif"></Image>
            <Box color="purple.700" fontSize="18px" my={0} textAlign={"center"}>
              ¿Dudas?
            </Box>
            <Box color="purple.700" fontSize="18px"  fontWeight={"bold"}  textAlign={"center"}>
              Pruébatelo gratis en casa
            </Box>
            <Divider my={2} h={"1px"} borderColor={"#808080"} />
            <Box pl={10} mt={2}>
              <Box color={"#8b8b8b"} fontSize="14px" p={0} textAlign={"left"}>
                ✓ Elije sin compromiso hasta 3 tallas
              </Box>
              <Box color={"#8b8b8b"} fontSize="14px" my={0} textAlign={"left"}>
                ✓ Envíos / devolución incluido
              </Box>
              <Box color={"#8b8b8b"} fontSize="14px" my={0} textAlign={"left"}>
               ✓ Pagas envío <span style={{textDecoration:"underline"}}>solo si compras</span>
              </Box>
            </Box>
            <Button 
              onClick={handleAddAddress}
              bgColor="#553C9A"
              fontSize="14px"
              h={"40px"}
              color={"white"}
              mb={2}
              mx={"auto"} 
              mt={5}
              w={"80%"}
              _hover={{ bg: "purple.400" }}
            >
                OK
            </Button>
            <Link color="grey" fontSize="14px" mb={5} mx={"auto"} textAlign={"center"}  textDecor={"underline"} onClick={() => widget.openCloseModalAskForAddress(false)}>No quiero probar antes de comprar</Link>
        </Flex>
      </Flex>
    </Box>
  );
};
