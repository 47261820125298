import { ApiResponse, HttpMethods, makeRequest } from './ApiService';
import { ShoppingCartModel, ShoppingCartProductModel, ShoppingCartData } from '../models/ShoppingCartModel';
import { Data } from "../helper/Data";

export class ShoppingCartService {

    /** Add a product to the shopping cart.
     * @param {ShoppingCartProductModel} scProduct - the product to be added to the shopping cart
     * @return {Promise<ShoppingCartModel[] | []>} the updated shopping cart or an empty array
     */
    public static async addToShoppingCart(scProduct: ShoppingCartProductModel): Promise<ShoppingCartModel[] | []> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/shoppingCart/add?hostId=${Data.getHostId()}`, scProduct);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return [];
            }
            return response.data as ShoppingCartModel[];
        } catch (error: any) {
            return [];
        }
    }

    /** Retrieves the shopping cart data from the server.
     * @return {Promise<any> | []} The shopping cart data from the server.
     */
    public static async getShoppingCart(): Promise<ShoppingCartData | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/shoppingCart/get?hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as ShoppingCartData;
        } catch (error: any) {
            return null;
        }
    }

    /** Deletes a product from the shopping cart.
     * @param {string} cartId - The ID of the shopping cart
     * @return {Promise<ShoppingCartModel[] | []>} The shopping cart data after deletion
     */
    public static async delShoppingCart(cartId:string): Promise<ShoppingCartModel[] | []> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, '/shoppingCart/delete', {
                shoppingCartId:cartId
            });
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                []
            }
            return response.data as ShoppingCartModel[];
        } catch (error: any) {
            console.log(`ShoppingCartService::addToShoppingCart fail: ${error.message}`);
            return [];
        }
    }
}
