import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class Newbalance extends Brands {

    public modeTest: boolean = false;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        const elementParent = document.getElementsByTagName('product-info')[0]
        const elementBefore = document.getElementsByClassName('product-form__input product-form__quantity')[0];
        if (elementParent != null || elementBefore != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            trycloudArea.style.cssText = "display: block;";
            elementParent?.insertBefore(trycloudArea, elementBefore.nextSibling);
            return trycloudArea;
        }
        console.log("No se encontró el elemento necesario para renderizar el widget");
        return null;
    }

    public prepareModal = (activate: boolean) => {
        const element = document.getElementsByTagName('product-info')[0];
        if (element != null && element.parentElement) {
                element.parentElement.style.cssText = activate ? "transform: none; animation: none; opacity: 1;" : "";
        }
    }

    selectElements = () => {
        let elements =[]; 
        elements.push(document.querySelector("#variant-radios-template--17769291284647__main > fieldset"));
        elements.push(document.querySelector("#Quantity-Form-template--17769291284647__main"));
        elements.push(document.querySelector("#ProductInfo-template--17769291284647__main > div:nth-child(12) > product-form"));
        elements.push(document.querySelector("#ProductInfo-template--17769291284647__main > div:nth-child(5)"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('products') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const element:NodeListOf<HTMLInputElement> = (document.getElementsByName('product-id') as NodeListOf<HTMLInputElement>);
        if (element && element.length > 0) {
            return element[0]?.value;
        }
        return '';
    }
}