import {
  Flex,
  Text,
  Button,
  Center,
  Image,
  Box,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useModaladdress2 } from "../../hooks/useModaladdress2";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { AddressModel } from "../../models/AddressModel";
import { prepareModal } from "../../helper/Render";
import { AuthService } from "../../services/AuthService";
import { useCityAddress } from "../../hooks/useCityAddress";

export const ModalAddress2 = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const auntoCompleteRef = useRef<HTMLInputElement>(null);
  const widget = useWidget();
  const addr = useAddress();
  const toast = useToast();
  const cityAddress = useCityAddress();

  
  if (widget == null) return <></>;

  prepareModal(widget.isOpenModalAdress);
  
  const {
    buttonSaveActive,
    styleInputMap,
    country,
    locality,
    formatAddress,
    latitude,
    longitude,
    iniMap,
  } = useModaladdress2();

  useEffect(() => {
    iniMap(mapRef.current, auntoCompleteRef.current);
  }, []);

  const addCityAddress = async(): Promise<boolean> => {
    const message = await cityAddress.addCityAddress();
    if (message.code === 400) {
      toast({
        title: message.title,
        description: message.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  /** A function that handles saving an address.
   * @return {Promise<void>} a promise with the result of saving the address
   */
  const handlerSaveAddress = async (): Promise<void> => {
      if (formatAddress == "") {
          toast({
            title: "Disculpe.",
            description: "Complete los datos de la dirección para continuar.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
      }
      const newAddress: AddressModel = {
        id: 0,
        customer_id: "",
        country_name: country,
        city_name: locality,
        address: formatAddress,
        additional_info: "",
        latitud: latitude,
        longitud: longitude,
        place_Id: "--",
        commission: 0,
      };
      if (!AuthService.validateSession()) {
        // INFO: Se coloca esto por que ahora que no esta la ventana que creaba el usuario con la ciudad. 
        //       Si no esta se tiene que crear primero para evitar que el modal falle la primera vez cuando aun no existe el usuario.
        const response = await addCityAddress();
        if (!response) return;
      }
      const result = await addr?.changeAddress(newAddress);
      if (result?.code === 200) {
        widget?.openCloseModalAddress(false);
        widget.handlerSteps("product");
        widget.openWidget();
      }
      toast({
        title: result?.title,
        description: result?.message,
        status: result?.type,
        duration: 9000,
        isClosable: true,
      });
  };

  return (
    <Box
      flexDir="column"
      alignItems="center"
      justifyContent={"center"}
      //justifyContent="center"
      position={"fixed"}
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg={"rgba(0, 0, 0, 0.5)"}
      zIndex="1000"
      display={!widget.isOpenModalAdress ? "none" : "flex"}
    >
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border={"1px solid #ddd"}
        shadow={"none"}
        overflowY="auto"
        borderRadius={"15px"}
        w={"90%"}
        maxW={"340px"}
        p="1rem"
        gap="0.4rem"
        display={!widget.isOpenModalAdress ? "none" : "flex"}
      >
        <Flex direction="row" justifyContent="space-between" w="100%">
          <Center w="100%">
            <Image
              boxSize="50px"
              objectFit="cover"
              opacity={1}
              src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/TryCloud-logo-sqr-small.png"
              alt="trycloud logo"
            />
          </Center>
          <Link
            color="purple.700"
            fontSize="25px"
            fontWeight="bold"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            onClick={() => widget.openCloseModalAddress(false)}
          >
            x
          </Link>
        </Flex>

        <Text fontWeight="bold" fontSize="26px" textAlign="center">
          Dime dónde estás
        </Text>
        <Text textAlign="center" w={"70%"} margin="auto">
          Para validar disponibilidad del servicio en tu zona
        </Text>
        <input
          type="text"
          id="place_input"
          ref={auntoCompleteRef}
          placeholder="Dirección o punto de referencia"
          style={styleInputMap}
        />
        <div 
          id="map" 
          ref={mapRef} 
          style={
            { width:"260px", 
              minHeight: "115px",
              display: formatAddress === "" ? "none" : "block",
              marginBottom: "10px"
            }}
          >
          </div>
        <Button
          onClick={handlerSaveAddress}
          w= {"260px"}
          bg="#553C9A"
          color="white"
          h={"40px"}
          fontSize={"14px"}
          isDisabled={!buttonSaveActive}
          isLoading={addr?.isLoading}
          loadingText="Espere un momento"
          _hover={{ bg: "purple.400" }}
        >
          Continuar
        </Button>
      </Flex>
    </Box>
  );
};
