import { Amplitud } from "../helper/Amplitud.ts";
import { ClarityModule } from "../helper/Clarity.ts";
import { Data } from "../helper/Data.ts";
import { DataConfigModel } from "../models/DataConfigModel.ts";


export const useDataConfig = () => {

    /** This function starts the process of showing the first time modal.
     * @return {Promise<boolean>}
     */
    const startShowFirstTimeModal = async (setIsOpenModalAskForAddress: (state: boolean) => void, configResponse: DataConfigModel | null): Promise<void> => {
        if (!configResponse || configResponse.openFirstModal.timeToRetryToActivateWidget === 0) {
            return;
        };
        const tryCloudWasActivated = localStorage.getItem("tryCloudWasActivated") as string;
        const lastTimeUsed = localStorage.getItem("lastTimeUsed") as string;
        const timeFromLastUse = new Date().getTime() - new Date(lastTimeUsed).getTime();

        if (tryCloudWasActivated === "false" ||
            tryCloudWasActivated === null ||
            timeFromLastUse > configResponse.openFirstModal.timeToRetryToActivateWidget) {
            setTimeout(() => {
                const tryCloud = localStorage.getItem("tryCloud") as string;
                if (tryCloud !== "true") {// Ya esta abierto el widget, asi que no hay que mostrarlo.
                    setIsOpenModalAskForAddress(true);
                    ClarityModule.activate();
                    Amplitud.activate();
                    ClarityModule.funnelModalTimer();
                    Amplitud.funnelModalTimer();
                }
                setDataWidgetOpen();// Igual marcamos para que no se intente mostrar el widget de nuevo.
            }, configResponse.openFirstModal.timeToShowFirstModal)
        }
    }

    /** This function sets the widget open data for the first time.
     * @return {void}
     */
    const setDataWidgetOpen = () => {
        localStorage.setItem("tryCloudWasActivated", "true");
        localStorage.setItem("lastTimeUsed", new Date().toString());
    }

    const setTimeToCloseWidgetByInactivity = (closeWidget: () => Promise<void>): void => {
        // Aplica solo para newbalance
        if (Data.getHostId() !== "279038") {
            return;
        }
        setTimeout(() => {
            const tryCloud = localStorage.getItem("tryCloud") as string;
            if (tryCloud === "true") {
                closeWidget();
                console.log("Se cerro el widget por inactividad");
            }
        }, calculateMillisecondsElapsed());//10 minutos.
    };

    const calculateMillisecondsElapsed = (): number => {
        const inactivityTime = 600000;//10 minutos
        // Obtener la date del localStorage 
        let dateStorage = localStorage.getItem('lastTimeUsed');
        // Obtener la date actual 
        let currentDate = new Date();
        if (!dateStorage) {
            console.error("No se encontró ninguna date en el localStorage con la clave proporcionada.");
            localStorage.setItem('lastTimeUsed', currentDate.toString())
            return inactivityTime;
        }
        // Convertir la date almacenada a un objeto Date 
        let date = new Date(dateStorage);

        //Calcular la diferencia en milisegundos 
        let miliseconds = currentDate.getTime() - date.getTime();
        console.log("Tiempo transcurrido: " + miliseconds);
        miliseconds = inactivityTime - miliseconds;
        console.log("Tiempo faltantes: " + miliseconds);

        return miliseconds;
    };


    return { startShowFirstTimeModal, setDataWidgetOpen, setTimeToCloseWidgetByInactivity };
}
