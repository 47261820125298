import { useState } from "react";
import { CitiesModel } from "../models/CitiesModel";
import { CityService } from "../services/Address/CityService";
import { MessageModel } from "../models/MessageModel";
import { AnonimosAddressModel } from "../models/AnonimousAddressModel";
import { AuthService } from "../services/AuthService";
import { useAddress } from "../providers/AddressProvider";

export const useCityAddress = () => {

    const [cities, setCities] = useState<CitiesModel[] | null>(null);
    const addr = useAddress();

    const getCities = async () => {
        const response: CitiesModel[] | null = await CityService.get();
        setCities(response);
     }

     /** Set customer in state.
      * @param {AnonimosAddressModel} data
      * */
     const setCustomerData = (data: AnonimosAddressModel) => {
        AuthService.setAuthModel({
            token: data.api_token.access_token,
            refreshToken:  data.api_token.refresh_token,
            isAnonimo: true
        });
        addr?.setCustomer(data.customer);
        localStorage.setItem('tryCloud', "true");
     }

    /** Create an anonimous address through city Id.
     *  - If it fails, it returns error message.
     *  - If it succeeds, 
     *      - Set auth model 
     *      - Set the customer in state.
     *      - Set local storage widget in true.
     * @param {string} cityId 
     * @returns 
     */
    const addCityAddress = async (): Promise<MessageModel> => {
        const data: AnonimosAddressModel | false = await CityService.addAddress();
        if (data === false || !data?.api_token) {
          return {
            code: 400,
            type: "error",
            title: "Disculpe.",
            message: "No hemos podido asociar esta ciudad a tu perfil."
          }
        }
        setCustomerData(data);
        await addr?.getDefaultAddress();
        return {
          code: 200,
          type: "success",
          title: "Bienvenido.",
          message: "Hemos asociado esta ciudad a tu perfil."
        }
    }

    /** Change the address of a city asynchronously.
     * @param {string} cityId - The ID of the city for which the address needs to be changed
     * @return {Promise<MessageModel>} A message model representing the result of the address change.
     */
    const changeCityAddress = async (cityId: string): Promise<MessageModel> => {
        const data: AnonimosAddressModel | false = await CityService.changeCityAddress(cityId);
        if (data === false) {
            return {
                code: 400,
                type: "error",
                title: "Disculpe.",
                message: "No hemos podido cambiarte de ciudad."
            }
        }
        await addr?.getDefaultAddress();
        return {
            code: 200,
            type: "success",
            title: "Bienvenido.",
            message: "Hemos cambiado tu ciudad."
        }
    }
  
    return { cities, getCities, addCityAddress, changeCityAddress }
}
