import { Brands } from "./Brands";
import { DataConfigModel } from "../../models/DataConfigModel";

export class BosiCo extends Brands {

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        const element = document.querySelector('div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--product-page-container-details-summary.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100');
        let anchorElement = document.querySelector('div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product-page-sku-selector-size');
        if (anchorElement == null) return;
        anchorElement = anchorElement?.parentElement;
        if (element != null && anchorElement != null) {
            const trycloudArea = document.createElement("div");
            trycloudArea.style.cssText = "margin-top: -20px;";
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            element?.insertBefore(trycloudArea, anchorElement.nextSibling);
            return trycloudArea;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    selectElements = () => {
        let elements =[];
        let elementChild = [];

        let elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--product-page-container-details-summary.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100")
         //Mobile (mobile)
         //elementParent = document.querySelector("div.vtex-flex-layout-0-x-flexCol.vtex-flex-layout-0-x-flexCol--phone__product--content.ml0.mr0.pl0.pr0.flex.flex-column.h-100.w-100");

        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            //Desktop
            //Colors y size (despktop and Mobile).
            elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product-page-sku-selector-size"));
            //Guia de Tallas
            elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--prduct-page-size-guide"));
            //cantidad
             elementChild.push(document.querySelector("div.vtex-flex-layout-0-x-flexRow.vtex-flex-layout-0-x-flexRow--product-page-quantity-addToCart"));
        }
        //
        //Trycloud
        let trycloudArea = document.getElementById("trycloud-area");
        if (elementParent != null && elementParent.childNodes != null && elementParent.childNodes.length > 0) {
            for (let index = 0; index < elementParent.childNodes.length; index++) {
                if ( trycloudArea == elementParent.childNodes[index] ) {
                    continue;
                }
                for (let indexChild = 0; indexChild < elementChild.length; indexChild++) {
                    const element = elementChild[indexChild];
                    if (element == elementParent.childNodes[index].firstChild) {
                        elements.push(elementParent.childNodes[index]);
                    }
                }
            }
        }
        elements.push(document.querySelector("div.pr0-ns.pb0.pb0-ns.items-stretch.vtex-flex-layout-0-x-stretchChildrenWidth.flex"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElements();

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElements(); 
        
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     /* public getProductId = (): string => {
        const elementProductData = document.querySelector("body > div.render-container.render-route-store-product > div > div.vtex-store__template.bg-base > div > div > div > script");
        if (!elementProductData || !elementProductData?.textContent) {
            return '';
        }
        const jsonData = JSON.parse(elementProductData.textContent) as any;
       return `P${jsonData.mpn as string}`;  
    } */

    public getProductModel = (): string => {
        const metaElement = document.querySelector('meta[property="product:retailer_item_id"]');
        if (!metaElement) return '';
        return metaElement.getAttribute('content') || '';
    };
}