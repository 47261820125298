import { Box, Divider, Flex, Img, Text} from "@chakra-ui/react";
import { useWidget } from "../../providers/WidgetProvider";

export const HeaderOff = () => {
  const widget = useWidget();
  if (widget == null) return(<></>);
  return (
    <Flex direction="column"  w="100%">
      <Flex direction="row" alignItems={"center"} pt={2}>
        <Flex direction={"column"} justifySelf={"center"}>
          <Text id="header-trycloud" pt={0} fontSize="17px" fontWeight="bold" color={"#404040"}>
          Pruébatelo antes de comprarlo
          </Text >
          <Text fontSize="12px" color={"#404040"}>
          Te lo enviamos en menos de 24hrs
          </Text>
        </Flex>
        <Divider orientation='vertical' ml={2} color={"#808080"}/>
        <Img src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/TryCloud-logo-sqr-grey.png" 
            alt="trycloud logo"
            h={"50px"}
            w={"55px"}
            ml={2}
            opacity={1}
            />
      </Flex>
      <Box
        id="header-trycloud"
        fontSize="12px"
        lineHeight="16px"
        textDecoration={"underline"}
        textAlign={"left"}
        color={"#404040"}
        cursor={"pointer"}
        onClick={() => widget.openCloseModalHowTowork(true)}
      >
        Conoce más.
      </Box>
    </Flex>
  );
};