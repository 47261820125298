import React from "react";
import {
  Product,
  VariationsSize,
  VariationsColor,
  productOutStock,
} from "../models/ProductModel";
import { ShoppingCartModel } from "../models/ShoppingCartModel";

export interface ProductContexType {
  product: Product | null;
  visitedProduct:  Product | null;
  colors: VariationsColor[] | [];
  sizes: VariationsSize[] | [];
  sizeSelected: VariationsSize | null;
  productOutStock: productOutStock | null;
  showReturn: boolean;
  productRelated: Product[] | null;
  loading: boolean;
  initialized: boolean;
  image: string;
  cart: ShoppingCartModel[] | [];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReturn: React.Dispatch<React.SetStateAction<boolean>>;
  setProductOutStock: React.Dispatch<React.SetStateAction<productOutStock | null>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  setSizeSelected: React.Dispatch<React.SetStateAction<VariationsSize | null>>;
  btnNext: () => void;
  btnPrev: () => void;
  handlerColor: (color: VariationsColor) => void;
  //handlerVariation: (sizeName: string) => Promise<Array<string|boolean>>;
  handlerReturn: () => void;
  delVariation: (TN:string) => void;
  fetchProduct: (newcart: ShoppingCartModel[] | undefined) => Promise<void>;
  fetchProductsRelated: (sizeName: string, TN: string) => Promise<void>;
}

export const ProductContext = React.createContext<ProductContexType | null>(null);
