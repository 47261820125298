import { useState, useContext, useEffect } from "react";
import { ShoppingCartModel } from "../models/ShoppingCartModel";
import { Product } from "../models/ProductModel";
import { ShoppingCartService } from "../services/ShoppingCartService";
import { ShoppingCartContextType, ShoppingCartContext } from "../context/ShoppingCartContext";
import { useWidget } from "./WidgetProvider";
import { AuthService } from "../services/AuthService";

export const ShoppingCartProvider = ({ children, }: { children: React.JSX.Element; }) => {
  const [cart, setCart] = useState<ShoppingCartModel[] | []>([]);
  const [cartDeleted, setCartDeleted] = useState<ShoppingCartModel[] | []>([]);
  const widget = useWidget();

  useEffect(() => {
    setCart([]);
    if (!widget?.isOn || !AuthService.validateSession()) return;
    getShoppingCart();
  }, [widget?.isOn]);

  const getShoppingCart = async (): Promise<ShoppingCartModel[] | []> => {
    try {
      //setCart([]);
      // Get shopping cart.
      const result = await ShoppingCartService.getShoppingCart();
      // If result is null, return the same cart.
      if (!result){
        setCart([]);
        return [];
      }  
      // Set cart from result.
      setCart(result.products);
      setCartDeleted(result.deleted);
      return result.products;
    } catch (error: any) {
      console.log("Error al obtener el carrito de compras");
      return cart;
    }
  };

  const addShoppingCart = async ( product: Product, TN: string, Upc: string, colorName: string, sizeName: string ): Promise<ShoppingCartModel[] | []> => {
    const elementCart = cart.find((item) => { return item.TN == TN; });
    if (elementCart !== undefined)  return cart;
    const result = await ShoppingCartService.addToShoppingCart({
      productId: TN,
      TN: TN,
      TNP: product.TNP,
      Upc: Upc,
      price: product.price,
      listPrice: product.listPrice,
      colorName: colorName,
      sizeName: sizeName,
      image: product.image,
      merchant: product.merchant,
    });
    if (!result) return cart;
    setCart(result);
    return result;
  };

  const delShoppingCart = async ( upc: string ): Promise<ShoppingCartModel[] | []> => {
    // Identify the product in the cart.
    const productCartArray = cart.filter((item) => item.upc == upc);
    // If the product is not in the cart, return the same cart.
    if (productCartArray.length == 0) return cart;
    const productCart = productCartArray[0];
    // Call the service to delete the product.
    const newCart = await ShoppingCartService.delShoppingCart(productCart.cardId);
    // If result is null, return the same cart.
    if (!newCart) {
      setCart(cart);
      return cart;
    } 
    // Set cart from result.
    setCart(newCart);
    return newCart;
  };

  const shopProp: ShoppingCartContextType = {
    cart,
    cartDeleted,
    getShoppingCart,
    addShoppingCart,
    delShoppingCart,
  };

  return (
    <ShoppingCartContext.Provider value={shopProp}>
      {children}
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
    const shopCart: ShoppingCartContextType | null = useContext(ShoppingCartContext);
    return shopCart;
}