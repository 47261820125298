import { DataConfigModel } from "../../models/DataConfigModel";

/** Structure for brands classes.
 */
export abstract class Brands {

     modeTest: boolean = true;
     startApp: (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => void  = () => null;
     getArea: () => HTMLElement | null | undefined = () => null;
     showElement: () => void = () => null;
     hiddenElements: () => void = () => null;
     hiddenHeader: (hidden: boolean) => void = () => null;
     prepareModal: (activate: boolean) => void = () => null;
     allowRender: () => boolean = () => true;
     getProductId: () => string = () => "";
     getProductModel: () => string = () => "";
     getSkuId: () => string | null = () => "";
     mutationObserver: (execute: () => void) => void = () => null;
}