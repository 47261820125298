import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class VansCo extends Brands{

    public modeTest: boolean = false;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        
        if (this.hasDiscountElement() && (localStorage.getItem("tryCloud") === "false" || localStorage.getItem("tryCloud") === null)) return null;
        // Box selector talla.
        let element = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.sku-selector-box")
        // If Element is not null, create element trycloud.   
        if (element != null) {
            // create element trycloud.
            const trycloudArea = document.createElement("div");
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            trycloudArea.style.cssText = "float: left;";
            
            // Append element trycloud.
            element?.parentNode?.insertBefore(trycloudArea, element?.nextSibling);
            return trycloudArea;
        }

        // Send metrics error.
        console.log("Trycloud: No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hasDiscountElement = () : Element | null  => {
        const element = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.cleaned-price > span.listPrice");
        return element === null  || element?.textContent === ""  ? null : element;
    }

    selectElement = () => {
        let elements = [];
        //Addi
        elements.push(document.querySelector("addi-product-widget"));
        //box color and box talla
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.sku-selector-box"));
        //Producto sugerido
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.prodSugerido"));
        //alert texto
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.txt-alert"));
        //box add to cart
        elements.push(document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.buy-button-box.hidden-xs.buy-button-box-sticky"));
        return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElement(); 

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
        this.additionalStyles();
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public showElement = () => {
        let elements = this.selectElement(); 

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
        this.additionalStyles();
    }

    public additionalStyles = () => {
        /* const style = document.createElement('style');
        style.innerHTML = `
            #espec_0_opcao_0 {
                order-width: 1px; 
                border-color: #000000;
            }
            #product-content > div > div.product-details > div.product-info.WishlistModule > div.prodSugerido > div > div > div.data-container > label > div:nth-child(3) > img {
                max-width: none;
            }
        `;
        document.head.appendChild(style); */
        let selectElement = document.querySelector("#espec_0_opcao_0") as HTMLElement;
        if (selectElement){
            selectElement.style.cssText = "border-width: 1px; border-color: #000000;";
        }
        let imageElement = document.querySelector("#product-content > div > div.product-details > div.product-info.WishlistModule > div.prodSugerido > div > div > div.data-container > label > div:nth-child(3) > img") as HTMLElement;
        if (imageElement){
            imageElement.style.cssText = "max-width: none;";
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

    public  getProductId = (): string => {
        //const elementData = document.querySelector("body > div.render-container.render-route-store-product > div > div.vtex-store__template.bg-base > div > div > div > script");
        const elementData = document.querySelector('meta[itemprop="productID"]');
        if (!elementData) return '';
        const data =  elementData.getAttribute('content');
        return data ? `P${data}` : '';
    };

    public specialRunWhenElementExists = (execute: () => void, numRetries = 10) : Promise<boolean> => {
        localStorage.setItem("tcdIsRender", "true");
        return new Promise((resolve) => {
            const elementButtonDesktop = document.querySelector("button.vtex-button.bw1.ba.fw5.v-mid.relative.pa0.lh-solid.br2.min-h-regular.t-action.bg-action-primary.b--action-primary.c-on-action-primary.hover-bg-action-primary.hover-b--action-primary.hover-c-on-action-primary.pointer.w-100");
            //const elementProductData = this.getProductIdArea();
            if (elementButtonDesktop || numRetries <= 0) {
                // Hacer algo con el elemento
                console.log('TryCloud: Elemento encontrado.');
                if (!document.querySelector('#trycloud-area')) {
                    execute();
                    resolve(true);
                    localStorage.setItem("tcdIsRender", "false");
                    console.log('TryCloud: El widget se ejecutó correctamente.');
                } else {
                    console.log('TryCloud: El widget ya se encuentra renderizado. No se ejecutó de nuevo. Intentos:', numRetries);
                }
            } else {
                console.log('TryCloud: Esperando el elemento DOM. Intento:', numRetries);
                // Esperar y volver a verificar después de un tiempo
                setTimeout(this.specialRunWhenElementExists.bind(null, execute, numRetries - 1), 1000);
            }
        });
    }

    /** Configura el observador de mutaciones.
     * @param execute 
     */
    public mutationObserver = (execute: () => void) => {
        const observer = this.mutationObserverInstance(execute);
        observer?.observe(document.body, { childList: true, subtree: true });
    }

    /** Instacia el observador de mutaciones.
     * @param execute 
     * @returns 
     */
    public mutationObserverInstance = (execute: () => void,) => {
        var nodeList = [];
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (mutation.target instanceof HTMLElement) {
                        if (this.allowRender()) {
                            nodeList.push(mutation.target.nodeName);
                        } else {
                            document.getElementById('trycloud-area')?.remove();
                        }
                    }
                }
            }
            if (localStorage.getItem("tcdIsRender") === "false" && nodeList.length > 0) {   
                 // If don't is render now and has changes in the DOM and is allow render (page product) and widget is not render yet.
                if (nodeList.length > 0 && this.allowRender() && !document.querySelector('#trycloud-area')) {
                    console.log(`TryCloud: NodeList: ${nodeList.length} | isExecuted: ${localStorage.getItem("tcdIsRender")} | allowRender: ${this.allowRender()}`);
                }        
                // compare productId
                this.compareProductId(); 
                nodeList = [];
                if (!document.querySelector('#trycloud-area')) {
                    console.log('TryCloud: Iniciando desde el observador...');
                    this.specialRunWhenElementExists(execute);
                } else {
                    // Validate if widget is Open to hide elements.
                    if (localStorage.getItem("tryCloud") === "true") {
                        this.hiddenElements();
                    }
                    console.log('TryCloud: Cambios detectados, pero el widget ya se encuentra renderizado.');
                }
            }
        });
        return observer;
    }

    /** Compare productId to know if it has changed */
    public compareProductId = () => {
        const productId = this.getProductId();
        if (productId) {
            if (productId !== localStorage.getItem("tcdProductId")) {
                localStorage.setItem("tcdProductId", productId);``
                document.getElementById('trycloud-area')?.remove();
            }
        }
    }
}