import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class RunningBalboa extends Brands {

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        const addToCartButtom = document.getElementById('addProductToCart');
        const internationalMsg = document.getElementById('internationalMsg');
        if (addToCartButtom != null || internationalMsg != null) {
            const trucloudArea = document.createElement("div");
            trucloudArea.setAttribute("id", "trycloud-area");
            trucloudArea.setAttribute("name", "trycloud-area");
            if (!internationalMsg) return null;
            addToCartButtom?.parentNode?.insertBefore(trucloudArea, internationalMsg.nextSibling);
            return trucloudArea;
        }
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenHeader = (hidden: boolean) => {
        let elements =[];
        elements.push(document.querySelector('header'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = hidden ? "display:none" : "";
            }
        }
    }

    public hiddenElements = () => {
        const addi = document.getElementById('addiWidget');
        const button = document.getElementById('addProductToCart');
        const size = document.getElementById('sizeContainer');
        if (button){
            if (button.className.includes('d-flex')){
             button.className = button.className.replace('d-flex', 'd-none');
            }
        }
        if (size){
            if (size.className.includes('d-flex')){
             size.className = size.className.replace('d-flex', 'd-none');
            }
        }
        if (addi) {
            if (addi.className.includes('d-flex')){
             addi.className = addi.className.replace('d-flex', 'd-none');
            }
        }
    }

    public showElement = () => {
        const addi = document.getElementById('addiWidget');
        const button = document.getElementById('addProductToCart');
        const size = document.getElementById('sizeContainer');
        if (button){
            if (button.className.includes('d-none')){
                button.className = button.className.replace('d-none', 'd-flex');
            }
        }
        if (size){
            if (size.className.includes('d-none')){
                size.className = size.className.replace('d-none', 'd-flex');
            }
        }
        if (addi) {
            if (addi.className.includes('d-none')){
                addi.className = addi.className.replace('d-none', 'd-flex');
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('-p-') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const productDataElement = document.getElementById('productData');
        if (productDataElement == null) return '';
        const productData = JSON.parse(productDataElement.innerText);
        return productData?.product?.model;
    }
}